import React,{useState,useEffect} from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  const [fields,setFields] = useState({nombre:'',base:'',db:'',user:'sistema@binario.com.py',pass:'satellite14*'});
  const [msj,setMsj] = useState({type:'',msj:''});
  const [bases,setBases] = useState([]);
  const urls = [
    {url:'/main/excecute/1',step:'Exportando base de datos original'},
    {url:'/main/excecute/2',step:'Importando base de datos con nuevo nombre'},
    {url:'/main/excecute/3',step:'Copiando sistema base'},
    {url:'/main/excecute/4',step:'Creando Cron'},
    {url:'/main/excecute/5',step:'Creando SSL'},
    {url:'/main/excecute/6',step:'Creando Usuario admin'},
  ];
  const CreateSystem = async ()=>{
    let headers = {'Content-Type': 'multipart/form-data'};
    for(var i in urls){
      let url = urls[i];
      try{
        setMsj({type:'info','msj':url.step});
        let response = await axios.post(url.url,{...fields},{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if(!response.data.success){
          setMsj({type:'danger','msj':`Error realizando tarea: ${url.step} (${response.data.message})`});
          break;
        }
      }catch(e){
        setMsj({type:'danger','msj':`Error realizando tarea: ${url.step} (${e.message})`});
        break;
      }
    }
  }
  const fetchBases = async ()=>{
    try{
      let b = await axios.get('/main/fetchBases');
      setBases(b.data);
    }catch(e){
      console.log(e);
      setMsj({type:'danger','msj':`Error consultando bases (${e.message})`});
    }
  }
  useEffect(()=>{
    fetchBases();
  },[])
  return (
    <div className="App">
      <div className="container">
        <div className="row justify-content-center align-items-middle align-self-middle" stype={{minHeight:'100vh'}}>
          <div className="col-12 col-md-4">
            <div className="card card-default">
              <div className="card-header">
                Instalador de sistemas
              </div>
              <div className="card-body">
                <form action="" onSubmit={(e)=>{e.preventDefault(); CreateSystem();}}>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="nombre" className="form-label">Nombre</label>
                        <div className="input-group">
                          <input name="nombre" onChange={(e)=>{setFields({...fields,nombre:e.target.value})}} value={fields.nombre} type="text" className="form-control" id="nombre" placeholder="nombre"/>
                          <span className="input-group-text">.binario.com.py</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="base" className="form-label">Sistema Base Original</label>
                        <select name="base" onChange={(e)=>{setFields({...fields,base:e.target.value})}} value={fields.base} id="base" className='form-control'>
                          <option value="---">Selecciona</option>
                          {bases.map(base=><option key={base.name} value={base.name}>{base.name}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="db" className="form-label">Base de datos</label>
                        <input name="db" onChange={(e)=>{setFields({...fields,db:e.target.value})}} value={fields.db} type="text" className="form-control" id="db" placeholder="nombre"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="user" className="form-label">Usuario Admin</label>
                        <input name="user" onChange={(e)=>{setFields({...fields,user:e.target.value})}} value={fields.user} type="text" className="form-control" id="db" placeholder="usuario@nombre.com"/>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="pass" className="form-label">Contraseña Admin</label>
                        <input name="pass" onChange={(e)=>{setFields({...fields,pass:e.target.value})}} value={fields.pass} type="text" className="form-control" id="db" placeholder="12346578"/>
                      </div>
                    </div>

                    {
                      (()=>msj.msj!==''?(<div className={`alert alert-${msj.type}`}>{msj.msj}</div>):'')()
                    }


                    <div className="col-12">
                      <div className="mb-3">
                        <button className="btn btn-success" type='submit'>Crear</button>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
